<template>
  <v-container fill-height justify-center>
    <div>
      <v-card outlined max-width="350" class="pa-4 text-center" rounded="lg">
        <v-card-text>
          <v-img
            max-height="100"
            contain
            alt="dealpartner"
            :src="require('@/assets/logo.png')"
            class="ma-auto"
          />
          <div class="text-h6">Sign in</div>
          <div class="mb-5">with your Email</div>
          <v-text-field
            label="Email"
            v-model="email"
            :rules="[rules.required, rules.email]"
            :error-messages="errorMessage"
            outlined
            dense
            ref="emailField"
            @keyup.enter="sendOtp()"
          ></v-text-field>
          <v-btn
            depressed
            block
            class="primary text-capitalize"
            @click="sendOtp"
            :loading="loading"
          >
            Next
          </v-btn>
          <div class="mt-5 text-caption">
            dealpartner.lk
          </div>
        </v-card-text>
      </v-card>
      <v-sheet flat max-width="350" color="transparent" class="text-right mt-2">
        <v-btn
          text
          x-small
          color="grey"
          class="text-capitalize"
          link
          href="https://dealpartner.lk/"
          target="blank"
        >
          Help
        </v-btn>
        <v-btn
          text
          x-small
          color="grey"
          class="text-capitalize"
          link
          href="https://dealpartner.lk/"
          target="blank"
        >
          Privacy
        </v-btn>
        <v-btn
          text
          x-small
          color="grey"
          class="text-capitalize"
          link
          href="https://dealpartner.lk/"
          target="blank"
        >
          Terms
        </v-btn>
      </v-sheet>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import { sentOTP } from "../../services/authService";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    email: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+\..+/.test(value) || "Invalid e-mail.";
      },
    },
    errorMessage: "",
    loading: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goNext() {
      this.$router.push({ name: "PageAuthOTP" });
    },
    async sendOtp() {
      if (this.email == "") {
        this.errorMessage = "Required.";
      } else {
        this.loading = true;
        let sentOTPReturn = await sentOTP({ email: this.email });
        this.loading = false;
        if (sentOTPReturn == "success") {
          this.goNext();
        }
      }
    },
  },
};
</script>